import React from "react";

// Sections
import Hero from "sections/home/hero";
import Trayectoria from "sections/home/trayectoria";
import HowItWorks from "sections/home/how-it-works";
import Cta from "sections/home/call-to-action";
import Options from "sections/home/options";
import QueEs from "sections/home/que-es";
import Testimonials from "sections/home/testimonials";
import TestimonialsUser from "sections/home/testimonials-user";
import Contact from "sections/home/contact";
import Faq from "sections/home/faq";
import Features from "sections/home/features";

import IconCheck from "assets/icons/icon-check.inline.svg";

const data = require("../assets/data/data.json");

const Gracias = () => (
  <>
    <div className="content d-flex align-items-center justify-content-center">
      <div style={{ paddingTop: "150px", paddingBottom: "50px" }}>
        <IconCheck className="mb-4" />
        <h4 className="mb-2 font-weight--700">
          Tu mensaje fue enviado con éxito.
        </h4>
        <p className="paragraph--small">
          Nuestro equipo se comunicará contigo lo antes posible.
          <br />
          ¡Gracias por comunicarte!
        </p>
      </div>
    </div>
    <Hero />
    <div className="gradient--blue">
      <Features />
      <Testimonials />
    </div>
    <QueEs />
    <HowItWorks />
    <Cta />
    <Options />
    <Trayectoria />
    {data.testimonials?.length && (
      <div className="gradient--blue">
        <TestimonialsUser />
      </div>
    )}
    <Contact />
    <Faq />
  </>
);

export default Gracias;
